// General Components styles
@use 'app/shared/styles/buttons';
@use 'app/shared/styles/checkbox';
@use 'app/shared/styles/avatar';
@use 'app/shared/styles/inputs';
@use 'app/shared/styles/select-overlay';
@use 'app/shared/styles/chips';
@use 'app/shared/styles/tooltip';
@use 'app/shared/styles/toastr';
@use 'app/shared/styles/featured-icons';
@use 'app/shared/styles/dynamic-dialog';
@use 'app/shared/styles/calendar';
@use "app/shared/styles/datepicker";

// TailwindCSS/PrimeNG Imports
@import "tailwindcss/base.css" layer(tailwindcss);
@import "tailwindcss/components.css" layer(tailwindcss);
@import "tailwindcss/utilities.css" layer(tailwindcss);
@import "primeng/resources/themes/aura-light-noir/theme.css";
@import "primeng/resources/primeng.css";

// import Quill styles in styles.scss
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";


body, .p-component {
  font-family: "Poppins", sans-serif;
}

/* Main font-size, it is important because the 'rem' unit has been used throughout the whole application */
html {
  font-size: 12px;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/** remove default buttons from search input */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.p-error {
  @apply font-normal text-red-600 text-[10px];
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #C1D9E3;
}

/** To customize icons by providing css variables */
.custom-icon {
  svg {
    width: var(--width);
    height: var(--height);
    path {
      stroke: var(--color);
    }
  }
}
