.p-tooltip-text {
  background-color: #6F94A3;
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  text-align: center;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow{
  border-bottom-color: #6F94A3;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow{
  border-top-color: #6F94A3;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow{
  border-left-color: #6F94A3;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow{
  border-right-color: #6F94A3;
}

.influence-degree-tooltip.p-tooltip {
  min-width: 204px;
}

.influence-degree-tooltip .p-tooltip-text {
  padding: 0.5px 0.5px;
  text-align: start;
  justify-content: start;
}

.influence-degree-tooltip.edit-mode.p-tooltip-top .p-tooltip-arrow {
  left: 97% !important;
}
